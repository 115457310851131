<!--预警消息-->
<template>
  <section class="warning-message-list">
    <section class="filter">
      <el-form :inline="true" :model="form">
        <el-form-item label="发布时间">
          <el-date-picker
            v-model="form.releaseTime"
            type="datetime"
            size="small"
            placeholder="选择开始日期"
            value-format="yyyy-MM-dd HH:mm:ss"
            >
          </el-date-picker><span style="margin: 0 5px">-</span>
          <el-date-picker
            v-model="form.endDate"
            type="datetime"
            size="small"
            placeholder="选择结束日期"
            value-format="yyyy-MM-dd HH:mm:ss"
          >
          </el-date-picker>
        </el-form-item>
<!--        <el-form-item label="消息类型">-->
<!--          <el-select v-model="form.type" placeholder="">-->
<!--            <el-option label="区域一" value="shanghai"></el-option>-->
<!--            <el-option label="区域二" value="beijing"></el-option>-->
<!--          </el-select>-->
<!--        </el-form-item>-->
        <el-form-item label="预警">
          <el-select placeholder="请选择相关预警" size="small" style="" v-model="form.warningId">
            <el-option style="width: 100%"
                       :key="-1"
                       label="全部"
                       :value="-1"
            ></el-option>
            <el-option style="width: 100%"
                       v-for="item in warningList"
                       :key="item.id"
                       :label="item.name"
                       :value="item.id">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="关键词">
          <el-input v-model="form.keyWords" size="small" placeholder=""></el-input>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" size="mini" @click="conditionSelect" :disabled="loading">查询</el-button>
        </el-form-item>
      </el-form>
    </section>
    <section class="content" v-loading="loading" >
      <el-tabs v-model="activeName" type="card" class="tabCard" @tab-click="handleClick">
        <el-tab-pane label="全部" name="all"></el-tab-pane>
        <el-tab-pane label="已忽略" name="ignored"></el-tab-pane>
      </el-tabs>
      <section class="content-wrap">
        <div class="controller">
          <div>
            <el-button type="primary" size="mini" @click="pushMessage">发送</el-button>
            <el-button type="primary" size="mini" @click="setToIgnore">忽略</el-button>
            <el-button type="primary" size="mini" @click="viewsDeleteWaringMessage">删除</el-button>
          </div>
          <!-- <el-button icon="el-icon-refresh" size="mini" class="refresh" @click="stopRefresh"></el-button> -->
        </div>
        <el-table :data="tableData" border style="width: 100%" max-height="calc(100% - 40px)"
         ref="messageId" @selection-change="handleSelectionChange"element-loading-text="正在查询">
          <el-table-column  :resizable="false" type="selection" align="center" width="60">
          </el-table-column>
          <el-table-column  :resizable="false" prop="emotion" align="center" width="80" label="情感">
            <template scope="{ row }">
                <el-tag size="mini" v-if="row.isDanger === 0" effect="dark" style="background-color: #84BFEC;border-color:#84BFEC;height:19px;padding:0 3px;border-radius:2px;">正</el-tag>
                <el-tag size="mini" v-else-if="row.isDanger === 1"  effect="dark" style="background-color: #ED9090;border-color:#ED9090;height:19px;padding:0 3px;border-radius:2px;">负</el-tag>
                <el-tag size="mini" v-else-if="row.isDanger === 2" effect="dark" style="background-color: #EDD990;border-color:#EDD990;height:19px;padding:0 3px;border-radius:2px;">敏</el-tag>
            </template>
          </el-table-column>
          <el-table-column :resizable="false" prop="title" :label="mode === 'list' ? '标题' : '标题+正文'" header-align="center">
            <template scope="{ row }">
              <dl @click="details(row.id)">
                <dt class="dataTitle" v-html="row.title" @click="getNewsDetailsThroughsn(row.sn)">{{ row.title }}</dt>
                <dd class="summary" v-if="mode === 'summary'" v-html="row.summary">{{ row.summary }}</dd>
              </dl>
            </template>
          </el-table-column>
          <el-table-column  :resizable="false" prop="warningName" align="center" width="200" label="预警">
          </el-table-column>
          <el-table-column  :resizable="false" prop="sourceName" align="center" width="100" label="来源">
          </el-table-column>
          <el-table-column  :resizable="false" prop="createDate" align="center" width="180" label="发布时间">
          </el-table-column>
          <el-table-column  :resizable="false" prop="status" align="center" width="100" label="状态">
            <template scope="{ row }">
              <el-tag v-if="row.status ===0" effect="dark" type="info" size="small">
                已忽略
              </el-tag>
              <el-tag v-if="row.status ===1" effect="dark" type="success" size="small">
                已处理
              </el-tag>
              <el-tag v-if="row.status ===2" size="small">
                未处理
              </el-tag>
            </template>
          </el-table-column>
<!--          <el-table-column  :resizable="false" prop="" align="center" width="100" label="操作">-->
<!--            <template scope="{ row }">-->
<!--              <i class="el-icon-more ellipsis" style="width: 100%;height: 100%;" tabindex="0" @click="handleOver(row)"-->
<!--                @blur="handleLeave(row)"></i>-->
<!--              <div class="dropdown" v-show="row.dropdownShow" @mouseleave="handleLeave(row)">-->
<!--                <li class="el-dropdown-menu__item" @mouseover="showStatusPanel" @mouseleave="hideStatusPanel">-->
<!--                  阅读状态<i class="el-icon-caret-right"></i>-->
<!--                  <div class="statusMenu" v-if="showStatusFlag">-->
<!--                    <ul class="status">-->
<!--                      <li>未读</li>-->
<!--                      <li>已读</li>-->
<!--                    </ul>-->
<!--                  </div>-->
<!--                </li>-->
<!--                <li class="el-dropdown-menu__item" @click="clickIgnore(row)">忽略</li>-->
<!--                <li class="el-dropdown-menu__item" @click="clickRemove(row)">删除</li>-->
<!--              </div>-->
<!--            </template>-->
<!--          </el-table-column>-->
        </el-table>
      </section>
      <section class="list-bottom">
        <span class="count">共{{count}}条记录</span>
        <el-pagination
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :page-sizes="[10 , 20, 30, 50]"
          :current-page="currentPage"
          :page-size="limit"
          layout="total, sizes, prev, pager, next, jumper"
          :total="count">
        </el-pagination>
      </section>
      <!-- <el-dialog title="预警详情" :visible.sync="dialogVisible" width="910px" :before-close="handleClose">
        <template>
          <span class="title">{{data.title}}</span>
          <div class="details">
            <p>[预警]</p>
            <p>标题：{{data.title}}</p>
            <p>摘要：{{data.content}}</p>
            <p>网站：{{data.source}}</p>
            <p>网址：http://baijiahao.baidu.com/s?id=1649624759471604439&wfr=spider&for=pc</p>
          </div>
        </template>
      </el-dialog> -->
    </section>
  </section>
</template>

<script>
import { initwarningMessage, ignoreWarningMessag, deleteWarningMessag, activePush } from '@/api/warningMessage'
import { listWarning } from '@/api/settings/warning'
export default {
  data () {
    return ({
      form: {
        releaseTime: '',
        type: '',
        keyWords: '',
        endDate: '',
        warningId: -1
      },
      activeName: 'all',
      mode: 'list',
      tableData: [],
      data: {},
      currCount: 0,
      count: 0,
      currentPage: 1,
      limit: 10,
      dialogVisible: false,
      showStatusFlag: true,
      loading: false,
      ids: [],
      status: '',
      messageId: [],
      multipleSelection: [],
      tab: '',
      warningList: []
    })
  },
  destroyed () {
    clearInterval(window.chatTimer)
  },
  methods: {
    async listAllWarning () {
      const res = await listWarning({ currentPage: 1, pageSize: 100 })
      this.warningList = res.data
    },
    getNewsDetailsThroughsn (sn) {
      console.log('57' + sn)
      const { href } = this.$router.resolve({
        name: 'warnNewsDetail',
        params: {
          sn: sn
        }
      })
      console.log(href)
      window.open(href, '_blank')
      // this.$router.push(`monitor/list/${this.$route.params.id}/${this.$route.params.nodeId}`)
    },
    stopRefresh () {
      // if (window.chatTimer > 0) {
      //   window.clearInterval(window.chatTimer)
      //   window.chatTimer = 0
      // }else {
      //   this.refresh()
      // }
    },
    refresh () {
      // window.chatTimer = setInterval(() => {
      //   this.currCount = this.count
      //   if (this.tab.name === 'ignored') {
      //     this.selectiIgnored()
      //   } else {
      //     this.showWaringMessage()
      //   }
      // },10000)
    },
    async pushMessage () {
      if (this.multipleSelection.length > 0) {
        for (var i = 0; i < this.multipleSelection.length; i++) {
          this.messageId.push(this.multipleSelection[i].id)
        }
        const res = await activePush({ ids: this.messageId })
        if (res.code === 0) {
          this.$message.success(res.msg)
        } else {
          this.$message.error(res.msg)
        }
        this.showWaringMessage()
      } else {
        this.$alert('至少选择一条数据', '提示', {
          confirmButtonText: '确定',
          type: 'warning'
        })
      }
    },
    async conditionSelect () {
      this.loading = true
      this.currentPage = 1
      this.limit = 10
      try {
        const res = await initwarningMessage({
          pageSize: this.limit,
          current: this.currentPage,
          keyWords: this.form.keyWords,
          releaseTime: this.form.releaseTime,
          endDate: this.form.endDate,
          warningId: this.form.warningId
        })

        this.tableData = res.data
        this.count = res.count
        this.loading = false
      } catch (err) {
        console.log(err)
      }
    },
    //  删除
    viewsDeleteWaringMessage () {
      if (this.multipleSelection.length > 0) {
        for (var i = 0; i < this.multipleSelection.length; i++) {
          this.messageId.push(this.multipleSelection[i].id)
        }
        try {
          this.$confirm('确定删除?', '提示', {
            confirmButtonText: '确定',
            cancelButtonText: '取消',
            type: 'warning'
          }).then(async () => {
            const res = await deleteWarningMessag({ messageId: this.messageId })
            if (res.code === 0) {
              this.$message({
                type: 'success',
                message: '删除成功!'
              })
              this.showWaringMessage()
            } else {
              this.$message.error('删除失败')
            }

            // this.$refs.multipleTable.clearSelection();
          }).catch(() => {
          })
        } catch (err) {
          console.log(err)
        }
      } else {
        this.$alert('至少选择一条数据', '提示', {
          confirmButtonText: '确定',
          type: 'warning'
        })
      }
    },
    async selectiIgnored () {
      if (this.count === 0) {
        this.loading = true
      }
      this.status = '0'
      try {
        const res = await initwarningMessage({
          pageSize: this.limit,
          current: this.currentPage,
          status: this.status
        })
        this.tableData = res.data
        this.count = res.count
        this.loading = false
        if (this.currCount > 0) {
          let num = this.count - this.currCount
          if (num > 0) {
            this.$message('已刷新' + num + '条数据')
          }
        }
      } catch (err) {
        console.log(err)
      }
    },
    handleSizeChange (val) {
      this.limit = val
      this.loading = true
      if (this.tab.name === 'ignored') {
        this.selectiIgnored()
      } else {
        this.showWaringMessage()
      }
    },
    handleCurrentChange (val) {
      this.currentPage = val
      this.loading = true
      if (this.tab.name === 'ignored') {
        this.selectiIgnored()
      } else {
        this.showWaringMessage()
      }
    },
    handleClick (tab, event) {
      this.tab = tab
      this.currentPage = 1
      this.loading = true
      if (tab.name === 'ignored') {
        this.selectiIgnored()
      } else {
        this.showWaringMessage()
      }
    },
    handleOver (row) {
      row.dropdownShow = true
    },
    handleLeave (row) {
      row.dropdownShow = false
    },
    clickIgnore (row) {
      row.dropdownShow = false
      console.log(row)
    },
    clickRemove (row) {
      row.dropdownShow = false
    },
    details (id) {
      this.tableData.forEach((item, index) => {
        if (item.id === id) {
          this.data = item
        }
      })
      this.dialogVisible = true
    },
    handleClose () {
      this.dialogVisible = false
    },
    showStatusPanel () {
      this.showStatusFlag = true
    },
    hideStatusPanel () {
      this.showStatusFlag = false
    },
    async showWaringMessage () {
      this.messageId = []
      this.ids = []
      if (this.count === 0) {
        this.loading = true
      }
      try {
        const res = await initwarningMessage({
          pageSize: this.limit,
          current: this.currentPage,
          keyWords: this.form.keyWords,
          releaseTime: this.form.releaseTime,
          endDate: this.form.endDate,
          warningId: this.form.warningId
        })
        this.tableData = res.data
        this.count = res.count
        this.loading = false
        if (this.currCount > 0) {
          let num = this.count - this.currCount
          if (num > 0) {
            this.$message('已刷新' + num + '条数据')
          }
        }
      } catch (err) {
        console.log(err)
      }
    },
    // 设为忽略
    async setToIgnore () {
      if (this.multipleSelection.length > 0) {
        try {
          for (var i = 0; i < this.multipleSelection.length; i++) {
            this.ids.push(this.multipleSelection[i].id)
          }
          const res = await ignoreWarningMessag({ ids: this.ids })
          if (res.code === 0) {
            this.$message.success('设置忽略成功')
            this.tableData = res.data
            this.count = res.count
            this.selectiIgnored()
            this.activeName = 'ignored'
          } else {
            this.$message.error('设置忽略失败')
          }
        } catch (err) {
          this.$message.error('设置忽略失败')
          console.log(err)
        }
      } else {
        this.$alert('至少选择一条数据', '提示', {
          confirmButtonText: '确定',
          type: 'warning'
        })
      }
    },
    handleSelectionChange (val) {
      this.multipleSelection = val
    }
  },
  mounted () {
    this.listAllWarning()
    var d = new Date()
    var year = d.getFullYear()
    var month = (d.getMonth() + 1) < 10 ? '0' + (d.getMonth() + 1) : (d.getMonth() + 1)//得到月
    var day = d.getDate() < 10 ? '0' + d.getDate() : d.getDate()//得到日
    var hour = ' 00:00:00'
    var hour1 = d.getHours() < 10 ? '0' + d.getHours() : d.getHours()// 得到时
    var minute = d.getMinutes() < 10 ? '0' + d.getMinutes() : d.getMinutes()// 得到分
    var second = d.getSeconds() < 10 ? '0' + d.getSeconds() : d.getSeconds()// 得到秒

    this.form.releaseTime = `${year}-${month}-${day}${hour}`
    this.form.endDate = `${year}-${month}-${day}` + ' ' + `${hour1}:${minute}:${second}`
    this.showWaringMessage()
    this.refresh()
  }
}
</script>

<style lang="scss">
  .warning-message-list {
    height: 100%;

    .filter {
      width: 100%;
      background: #fff;
      border-radius: 2px;
      padding-top: 15px;
      padding-left: 20px;
      box-shadow: 0px 0px 6px 0px rgba(53, 64, 82, 0.06);
    }

    .refresh {
      position: absolute;
      right: 0;
      margin-right: 110px;
    }
    .content {
      width: 100%;
      min-height: calc(100% - 160px);
      margin-top: 20px;
      background: #fff;
      border-radius: 2px;
      box-shadow: 0px 0px 6px 0px rgba(53, 64, 82, 0.06);
      padding: 20px 30px;
      position: relative;

      .controller {
        display: flex;
        justify-content: space-between;
        margin-bottom: 20px;

        .el-radio-button {
          width: 35px;
          height: 30px;
          padding: 0;

          .el-radio-button__inner {
            padding: 7px 10px;
          }
        }
      }

      .el-table {
        margin-bottom: 50px;
        /* overflow: visible;

        .el-table__body-wrapper {
          overflow: visible;
        } */

        .cell {
          .ellipsis {
            &:focus {
              cursor: pointer;
              outline: none;
            }
          }

          .dropdown {
            position: absolute;
            z-index: 999;
            background: #fff;
            left: 0;
            box-shadow: 0px 4px 10px 0px rgba(0, 0, 0, 0.1);
            border-radius: 2px;
            .el-dropdown-menu__item{
              position: relative;
              padding: 0 10px;
              .statusMenu{
                width: 80px;
                box-shadow: 0px 8px 10px 0px rgba(0, 0, 0, 0.1);
                background: #fff;
                text-align: center;
                position: absolute;
                top: 0;
                left:-80px;
                .status li{
                  width: 80px;
                  margin-left: -40px;
                  color: #606266;
                  &:hover{
                    color: #409EFF;
                  }
                }
              }
            }
          }

          dl {
            font-size: 14px;
            margin: 0;
            cursor: pointer;

            dt.dataTitle {
              color: #409EFF;
              text-overflow: -o-ellipsis-lastline;
              overflow: hidden;
              text-overflow: ellipsis;
              display: -webkit-box;
              -webkit-line-clamp: 2;
              line-clamp: 2;
              -webkit-box-orient: vertical;
            }

            dd.summary {
              color: #909399;
              margin-inline-start: 0;
              text-overflow: -o-ellipsis-lastline;
              overflow: hidden;
              text-overflow: ellipsis;
              display: -webkit-box;
              -webkit-line-clamp: 3;
              line-clamp: 3;
              -webkit-box-orient: vertical;
            }
          }
        }
      }

      .list-bottom {
        display: flex;
        justify-content: space-between;
        position: absolute;
        bottom: 20px;
        left: 20px;
        right: 20px;

        .count {
          line-height: 32px;
        }
      }

      .selected {
        background: rgba(64, 158, 255, .2);
      }

      .el-dialog {
        border-radius: 10px;

        .el-dialog__header {
          border-bottom: 1px solid #D7D7D7;

          .el-dialog__title {
            font-size: 20px;
          }
        }

        .el-dialog__body {
          .title {
            color: #333333;
            font-size: 24px;
          }

          .details {
            padding: 20px;
            border: 1px solid #D7D7D7;
            border-radius: 10px;
            margin-top: 16px;

            p {
              margin: 0;
              margin-bottom: 5px;
              font-size: 16px;
              color: #333;

              &:last-child {
                margin-bottom: 0;
              }
            }
          }
        }
      }
    }
  }
</style>
