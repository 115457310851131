import request from '@/utils/request'
// 查询消息列表
export function initwarningMessage (data) {
  return request({
    url: '/warningMessag/listWarningMessag',
    method: 'POST',
    data
  })
}
// 将消息设为忽略
export function ignoreWarningMessag (data) {
  return request({
    url: '/warningMessag/ignoreWarningMessag',
    method: 'POST',
    data
  })
}

// 删除预警消息
export function deleteWarningMessag (data) {
  return request({
    url: '/warningMessag/deleteWarningMessag',
    method: 'POST',
    data
  })
}

export function activePush (data) {
  return request({
    url: '/warningMessag/activePush',
    method: 'POST',
    data
  })
}
